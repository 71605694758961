<template>
  <div
    class="
      dropdown-menu
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-800
      menu-state-bg-light-primary
      fw-bold
      py-4
      w-250px
      fs-6
    "
    id="interviewDropdown"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a
        v-if="jobPosting.status === 'ACTIVE'"
        @click="changeStatus(jobPosting.id, false)"
        class="menu-link px-5"
      >
        {{ $t("common.button.setPassive") }}
      </a>
      <a
        v-if="jobPosting.status === 'PASSIVE'"
        @click="changeStatus(jobPosting.id, true)"
        class="menu-link px-5"
      >
        {{ $t("common.button.setActive") }}
      </a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <!-- <div class="menu-item px-5">
      <a href="#" @click="copyJobPosting()" class="menu-link px-5">
        {{ $t("common.button.copy") }}
      </a>
    </div> -->
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <a
        href="#"
        v-if="
          jobPosting.hasCvAnalysis === false &&
          jobPosting.hasCompletedInterviewSession === false
        "
        data-bs-toggle="modal"
        :data-bs-target="'#kt_modal_update_role' + jobPostingId"
        class="menu-link px-5"
      >
        {{ $t("common.button.edit") }}
      </a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a
        v-if="
          jobPosting.hasCvAnalysis === false &&
          jobPosting.hasCompletedInterviewSession === false
        "
        href="#"
        @click="deleteJobPosting(jobPostingId)"
        class="menu-link text-danger px-5"
      >
        {{ $t("modals.interview.deleteJobPosting") }}
      </a>
    </div>
    <!--end::Menu item-->
  </div>

  <!--begin::Modal-->
  <div
    class="modal fade"
    :id="'kt_modal_update_role' + jobPostingId"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-900px mh-900px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2 class="fw-bold">{{ $t("modals.interview.editJobPosting") }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-kt-roles-modal-action="close"
          >
            <i class="ki-duotone ki-cross fs-1"
              ><span class="path1"></span><span class="path2"></span
            ></i>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 my-7">
          <!--begin::Tabs-->
          <ul
            class="
              nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
              border-0
              fs-4
              fw-semibold
              mb-8
            "
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <a
                class="nav-link text-active-primary pb-4 active"
                data-bs-toggle="tab"
                :href="'#kt_modal_tab_details' + jobPostingId"
                role="tab"
                aria-selected="true"
              >
                {{ $t("modals.interview.jobPostingDetails") }}
              </a>
            </li>

            <li class="nav-item" role="presentation">
              <a
                class="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                :href="'#kt_modal_tab_qualifications' + jobPostingId"
                role="tab"
                aria-selected="false"
              >
                {{ $t("modals.interview.qualifications") }}
              </a>
            </li>
          </ul>
          <!--end::Tabs-->

          <div class="tab-content">
            <!--begin::Tab Pane 1-->
            <div
              class="tab-pane fade show active"
              :id="'kt_modal_tab_details' + jobPostingId"
              role="tabpanel"
            >
              <!--begin::Form-->
              <Form
                id="kt_modal_update_role_form"
                class="form fv-plugins-bootstrap5 fv-plugins-framework"
                @submit="updateJobPosting"
                :validation-schema="form"
              >
                <!--begin::Scroll-->
                <div
                  class="d-flex flex-column scroll-y me-n7 pe-7"
                  :id="'kt_modal_update_role_scroll' + jobPostingId"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_update_role_header"
                  data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
                  data-kt-scroll-offset="300px"
                  style="max-height: 541px"
                >
                  <!--begin::Input group-->
                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2">
                      <span class="required">
                        {{ $t("modals.interview.jobPostingTitle") }}</span
                      >
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-solid"
                      name="title"
                      v-model="jobPosting.title"
                    />
                    <ErrorMessage
                      name="title"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                    <!--end::Input-->
                    <div
                      class="
                        fv-plugins-message-container
                        fv-plugins-message-container--enabled
                        invalid-feedback
                      "
                    ></div>
                  </div>
                  <!--end::Input group-->

                  <div class="fv-row mb-5">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2 required">{{
                      $t("modals.interview.jobPostingDesc")
                    }}</label>
                    <!--end::Label-->

                    <vue-editor
                      name="description"
                      v-model="jobPosting.description"
                      class="form-control form-control-solid"
                      style="padding: 0px"
                      :editor-options="editorOptions"
                    ></vue-editor>
                    <ErrorMessage
                      name="description"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>

                  <div class="row mt-4">
                    <!-- Başlangıç Tarihi -->
                    <div class="col-md-6">
                      <div class="fv-row mb-10">
                        <label
                          class="
                            d-flex
                            align-items-center
                            form-label
                            mb-5
                            required
                          "
                          >{{ $t("modals.interview.startDate") }}</label
                        >
                        <div class="position-relative align-items-center">
                          <Field
                            type="date"
                            name="startDate"
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            :placeholder="$t('modals.interview.enterStartDate')"
                            :min="today"
                            v-model="startDate"
                          />
                          <ErrorMessage
                            name="startDate"
                            class="
                              fv-plugins-message-container
                              invalid-feedback
                            "
                          ></ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <!-- Bitiş Tarihi -->
                    <div class="col-md-6 fv-row">
                      <label
                        class="
                          d-flex
                          align-items-center
                          form-label
                          mb-5
                          required
                        "
                        >{{ $t("modals.interview.endDate") }}</label
                      >
                      <div class="position-relative align-items-center">
                        <Field
                          type="date"
                          name="endDate"
                          class="
                            form-control form-control-lg form-control-solid
                          "
                          :placeholder="$t('modals.interview.enterEndDate')"
                          :min="startDate"
                          v-model="endDate"
                        />
                        <ErrorMessage
                          name="endDate"
                          class="fv-plugins-message-container invalid-feedback"
                        ></ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div class="fv-row mb-5">
                    <div
                      class="w-100"
                      v-if="
                        jobPosting.interviews &&
                        jobPosting.interviews.length > 1
                      "
                      @dragover.prevent="preventScroll"
                    >
                      <label class="fs-5 fw-bold form-label mb-1">{{
                        $t("modals.interview.sortInterviews")
                      }}</label>

                      <div class="text-gray-400 fw-bold fs-8 mb-3">
                        {{ $t("modals.interview.sortInterviewsDesc") }}
                      </div>

                      <div class="d-grid gap-4 grid-columns-4">
                        <div
                          v-for="(interview, index) in jobPosting.interviews"
                          :key="index"
                          class="interview-card"
                          :class="{
                            dragging: index === draggedIndex,
                            'drag-over': index === dragOverIndex,
                          }"
                          draggable="true"
                          @dragstart="onDragStart(index)"
                          @dragover.prevent="onDragOver(index)"
                          @dragleave="onDragLeave"
                          @dragend="onDragEnd"
                          @drop="onDrop(index)"
                        >
                          <div class="interview-content">
                            <div class="interview-order">{{ index + 1 }}</div>
                            <h3 class="fw-bold">{{ interview.title }}</h3>
                            <p class="text-gray-500">
                              {{ interview.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Scroll-->

                <!--begin::Actions-->
                <div class="text-center pt-15">
                  <button
                    type="reset"
                    class="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("common.button.cancel") }}
                  </button>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-kt-roles-modal-action="submit"
                  >
                    <span class="indicator-label">
                      {{ $t("common.button.update") }}
                    </span>
                    <span class="indicator-progress">
                      {{ $t("common.loading") }}
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                </div>
                <!--end::Actions-->
              </Form>
              <!--end::Form-->
            </div>
            <!--end::Tab Pane 1-->

            <!--begin::Tab Pane 2-->
            <div
              class="tab-pane fade"
              :id="'kt_modal_tab_qualifications' + jobPostingId"
              role="tabpanel"
            >
              <!--begin::Permissions-->
              <div class="fv-row mb-5" style="max-height: 541px">
                <label class="fs-5 fw-bold form-label mb-2">{{
                  $t("modals.interview.qualifications")
                }}</label>

                <div class="d-flex">
                  <input
                    type="text"
                    v-model="featureInput"
                    @keyup.enter="addQualification"
                    class="form-control me-3"
                    :placeholder="$t('modals.interview.forAddQualification')"
                    v-on:keydown.enter.prevent
                  />
                  <button
                    class="btn btn-secondary ms-2"
                    type="button"
                    @click="addQualification"
                  >
                    {{ $t("common.button.add") }}
                  </button>
                </div>
                <ul
                  class="list-group mt-2"
                  v-if="jobPosting.jobPostingQualifications"
                >
                  <li
                    v-for="(
                      qualification, index
                    ) in jobPosting.jobPostingQualifications"
                    :key="index"
                    class="list-group-item d-flex justify-content-between"
                  >
                    {{ qualification.text }}
                    <button
                      type="button"
                      @click="removeQualification(qualification.id)"
                      class="btn btn-sm btn-danger"
                    >
                      {{ $t("common.button.delete") }}
                    </button>
                  </li>
                </ul>

                <!-- <div class="mb-6 fv-row">
                    <input
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      v-model="featureInput"
                      @keyup.enter="addQualification"
                      placeholder="Özellik ekleyin ve Enter'a basın"
                      v-on:keydown.enter.prevent
                    />
                  </div>

                  <div v-if="jobPosting.jobPostingQualifications" class="card-body pt-0">
                    <div class="d-flex flex-column text-gray-600">
                      <div
                        v-for="(feature, index) in jobPosting.jobPostingQualifications"
                        :key="index"
                        class="d-flex align-items-center py-2"
                      >
                        <span class="bullet bg-primary me-3"></span>
                        {{ feature }}
                      </div>
                    </div>
                  </div> -->
              </div>
              <!--end::Permissions-->
            </div>
            <!--end::Tab Pane 2-->
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal-->
</template>

<script lang="ts">
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { UpdateJobPostingModel } from "@/domain/interviewer/update-job-posting/model/UpdateJobPostingModel";
import { AddQualificationModel } from "@/domain/interviewer/qualification/add-qualification/model/AddQualificationModel";
import { RemoveQualificationModel } from "@/domain/interviewer/qualification/remove-qualification/model/RemoveQualificationModel";
import striptags from "striptags";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";
import { UpdateJobPostingStatusModel } from "@/domain/interviewer/update-job-posting-status/model/UpdateJobPostingStatusModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { useRouter } from "vue-router";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "jobposting-dropdown",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  props: {
    jobPostingId: Number,
  },
  setup(props) {
    const router = useRouter();
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");
    const featureInput = ref("");
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const jobPosting = ref<JobPostingDetailModel>({});
    const isChanged = ref<boolean>(false);

    const interviews = ref();
    const interviewSequence = ref<InterviewSequenceModel[]>();

    const form = Yup.object().shape({
      title: Yup.string()
        .required(t("validators_error.required"))
        .label("title"),
      //       description: Yup.string()
      // .test(
      //   "is-valid-description",
      //   t("validators_error.required"),
      //   (value) => striptags(value || "").trim() !== ""
      // )
      // .required(t("validators_error.required"))
      // .label("description"),
      startDate: Yup.date()
        .required(t("validators_error.required"))
        .label("startDate"),
      endDate: Yup.date()
        .required(t("validators_error.required"))
        .label("endDate")
        .test(
          "is-greater",
          t("validators_error.startDateBiggerEndDate"),
          function (value) {
            const { startDate } = this.parent;
            return startDate
              ? new Date(value as Date) >= new Date(startDate as Date)
              : true;
          }
        ),
      //     features: Yup.array()
      // .of(Yup.string().required(t("validators_error.required")))
      // .min(3, t("validators_error.minFeatures", { min: 3 }))
      // .label("Features"),
    });

    const getJobPostingList = (page) => {
      isChanged.value = false;

      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: props.jobPostingId,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
            if (jobPosting.value.interviews)
              interviews.value = jobPosting.value.interviews;

            interviewSequence.value = interviews.value.map((interview, idx) => {
              return new InterviewSequenceModel(interview.id, idx + 1);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const copyJobPosting = () => {
      Swal.fire({
        title: t("swalMessages.COPY_JOBPOSTING"),
        text: t("swalMessages.COPY_JOBPOSTING_DESC"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.SUCCESSFUL"),
            text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_JOBPOSTING),
            icon: "success",
            heightAuto: false,
          }).then(() => {
            window.location.reload();
          });
        }
      });
    };

    // Status değiştirme işlemi
    const changeStatus = (jobPostingId?: number, status?: boolean) => {
      const updateJobPostingStatusModel: UpdateJobPostingStatusModel = {
        id: jobPostingId,
        isActive: status,
      };

      const titleKey = status
        ? "swalMessages.MAKE_ACTIVE_JOBPOSTING"
        : "swalMessages.MAKE_PASSIVE_JOBPOSTING";
      const descriptionKey = status
        ? "swalMessages.MAKE_ACTIVE_JOBPOSTING_DESC"
        : "swalMessages.MAKE_PASSIVE_JOBPOSTING_DESC";

      Swal.fire({
        title: t(titleKey),
        text: t(descriptionKey),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .updateJobPostingStatus(updateJobPostingStatusModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(
                    status === true
                      ? SWAL_MESSAGES.SUCCESS.SUCCESS_MAKE_ACTIVE_JOBPOSTING
                      : SWAL_MESSAGES.SUCCESS.SUCCESS_MAKE_PASSIVE_JOBPOSTING
                  ),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {
                  store.dispatch(
                    "changedAnyJobPosting",
                    store.getters.changedAnyJobPosting + 1
                  );
                  // window.location.reload();
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    // Silme işlemi
    const deleteJobPosting = (jobPostingId?: number) => {
      const jobPostingDeleteModel: DeleteJobPostingModel = {
        id: jobPostingId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_JOBPOSTING"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteJobPosting(jobPostingDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_JOBPOSTING),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {
                  store.dispatch(
                    "changedAnyJobPosting",
                    store.getters.changedAnyJobPosting + 1
                  );
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPosting = () => {
      const errors: string[] = [];

      if (
        !jobPosting.value.description ||
        striptags(jobPosting.value.description.trim()) === ""
      ) {
        errors.push(t("validators_error.requiredDesc"));
      }
      if (errors.length > 0) {
        Swal.fire({
          icon: "error",
          title: t("validators_error.title"),
          html: errors.join("<br />"),
          heightAuto: false,
        });
        return;
      } else {
        const jobPostingUpdateModel: UpdateJobPostingModel = {
          id: props.jobPostingId,
          title: jobPosting.value.title,
          description: jobPosting.value.description,
          startDate: new Date(startDate.value),
          endDate: new Date(endDate.value),
        };

        if (interviewSequence.value) {
          jobPostingUpdateModel.interviews = interviewSequence.value;
        }

        interviewerController
          .updateJobPosting(jobPostingUpdateModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                title: t("swalMessages.SUCCESSFUL"),
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_JOBPOSTING),
                icon: "success",
                heightAuto: false,
              }).then(() => {
                window.location.reload();
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      }
    };

    const addQualification = () => {
      if (
        jobPosting.value.jobPostingQualifications &&
        jobPosting.value.jobPostingQualifications.length >= 20
      ) {
        swalNotification.error(
          t("validators_error.max20Qualification"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } 

      const addQualificationModel: AddQualificationModel = {
        id: props.jobPostingId,
        text: featureInput.value,
      };

      interviewerController
        .addQualification(addQualificationModel)
        .then((response) => {
          if (response.isSuccess) {
            featureInput.value = "";
            getJobPostingList(1);
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeQualification = (qualificationId?: number) => {
      if (
        jobPosting.value.jobPostingQualifications &&
        jobPosting.value.jobPostingQualifications.length <= 3
      ) {
        swalNotification.error(
          t("validators_error.min3Qualification"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } 
        const removeQualificationModel: RemoveQualificationModel = {
          jobPostingId: props.jobPostingId,
          qualificationId: qualificationId,
        };

        interviewerController
          .removeQualification(removeQualificationModel)
          .then((response) => {
            if (response.isSuccess) {
              Swal.fire({
                title: t("swalMessages.SUCCESSFUL"),
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_REMOVE_QUALIFICATION),
                icon: "success",
                heightAuto: false,
              }).then(() => {
                getJobPostingList(1);
              });
            } else {
              swalNotification.error(
                response.error.cause.cause +
                  t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                  response.error.code +
                  response.error.cause.code,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });

    };

    const editorOptions = ref({
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Başlık seçenekleri
          ["bold", "italic", "underline", "strike"], // Metin stilleri
          [{ list: "ordered" }, { list: "bullet" }], // Liste türleri
          [{ script: "sub" }, { script: "super" }], // Alt ve üst simgeler
          [{ indent: "-1" }, { indent: "+1" }], // Girintiler
          [{ direction: "rtl" }], // Metin yönü
          [{ color: [] }, { background: [] }], // Renkler
          [{ align: [] }], // Hizalama
          ["link"], // Bağlantı
          ["clean"],
        ],
        clipboard: {
          matchVisual: false,
        },
      },
    });

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
        interviewSequence.value = interviews.value.map((interview, idx) => {
          return new InterviewSequenceModel(interview.id, idx + 1);
        });
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    onMounted(() => {
      getJobPostingList(1);
    });

    return {
      today,
      startDate,
      endDate,
      editorOptions,
      copyJobPosting,
      changeStatus,
      deleteJobPosting,
      updateJobPosting,
      jobPosting,
      addQualification,
      removeQualification,
      featureInput,
      form,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
    };
  },
});
</script>
